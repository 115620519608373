
<template>
  <v-dialog
    v-model="showing_modal"
    width="700"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Canal: {{ channel.name }}
      </v-card-title>
      
      
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-expansion-panels multiple :value="panelGeneral" focusable>
              <v-expansion-panel  >
                <v-expansion-panel-header>General</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-for="(value, key, index) in channel" :key="`item_list_${index}`">
                    <v-list-item two-line v-if="value !== undefined && value !== null && (Object.keys(parserGeneral).includes(key))">
            
                      <v-list-item-content v-if="key == 'companies'">
                        <v-list-item-title>{{ parserGeneral[key] }}</v-list-item-title>
            
                        <v-list-item-subtitle>
                          <span v-if="!value || !value.length">Sin empresas asociadas</span>
            
                          <div v-else>
                            <div v-for="(company_id, idx) in value" :key="`item_company_${idx}`">
                              - {{ getCompanyName(company_id) }}
                            </div>
            
                          </div>
                        </v-list-item-subtitle>
            
                      </v-list-item-content>
            
            
                      <v-list-item-content v-else-if="key == 'campaign_block'">
                          <v-list-item-title>{{ parserGeneral[key] }}</v-list-item-title>
              
                          <v-list-item-subtitle>
                            <div >
                              <div class="mt-1" v-for="(info, key, idx) in value" :key="idx">
                                <b> {{ campaignBlockParser[key] }}</b>
                                <span v-if="key === 'status'"> {{ info.label }}</span>
                                <span v-else-if="key === 'start_break_date'"> {{ info ? info.replace('T', ' ').replace('Z', '') : 'No hay'}}</span>
                                <span v-else-if="key === 'end_break_date'"> {{info ? info.replace('T', ' ').replace('Z', '') : 'No hay' }}</span>
                                <span v-else> {{ parseValue(false, info) }}</span>
                              </div>
                            </div>
                          </v-list-item-subtitle>
              
                        </v-list-item-content>
            
                      <v-list-item-content v-else>
                        <v-list-item-title>{{ parserGeneral[key] }}</v-list-item-title>
                        <v-list-item-subtitle>{{ parseValue(key, value) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <v-col cols="12" md="12">
            <v-expansion-panels multiple :value="panelUltra" focusable>
              <v-expansion-panel>
                <v-expansion-panel-header>{{ apiTitle }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-for="(value, key, index) in channel" :key="`item_list_${index}`">
                    <v-list-item 
                      two-line 
                      v-if="value !== undefined && value !== null && 
                      (Object.keys(parserUltra).includes(key) || key === 'evolution_token'|| key === 'evolution_id')"
                    >
                      <!-- Mostrar configuración si es Ultramsg -->
                      <template v-if="channel.integration_api === 0">
                        <v-list-item-content v-if="key === 'settings'">
                          <v-list-item-title>{{ parserUltra[key] }}</v-list-item-title>
                          <v-list-item-subtitle>
                            <div>
                              <div class="mt-1" v-for="(setting, key, idx) in value" :key="idx">
                                <b> {{ settingsParser[key] }}</b>
                                <span> {{ parseValue(false, setting) }}</span>
                              </div>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-content v-else>
                          <v-list-item-title>{{ parserUltra[key] }}</v-list-item-title>
                          <v-list-item-subtitle>{{ parseValue(key, value) }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>

                      <template v-else-if="channel.integration_api === 1">
                        <v-list-item-content v-if="key === 'evolution_token'">
                          <v-list-item-title>Token de la instancia en Evolution</v-list-item-title>
                          <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="key === 'evolution_id'">
                          <v-list-item-title>Nombre de la instancia en Evolution</v-list-item-title>
                          <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>

                      <!-- Mostrar solo el token si es Evolution API -->
                      <template v-else-if="channel.integration_api === 2">
                        <v-list-item-content v-if="key === 'evolution_token'">
                          <v-list-item-title>Token de la instancia en Evolution</v-list-item-title>
                          <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-if="key === 'evolution_id'">
                          <v-list-item-title>ID de la instancia en Evolution</v-list-item-title>
                          <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <v-col cols="12" md="12">
            <v-expansion-panels multiple :value="panelRespond" focusable>
              <v-expansion-panel  >
                  <v-expansion-panel-header>Respond</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(value, key, index) in channel" :key="`item_list_${index}`">
                      <v-list-item two-line v-if="value !== undefined && value !== null && (Object.keys(parserRespond).includes(key))">
              
                        <v-list-item-content>
                          <v-list-item-title>{{ parserRespond[key] }}</v-list-item-title>
                          <v-list-item-subtitle>{{ parseValue(key, value) }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
  
        </v-row>
        <v-list>


        </v-list>

      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close()">
          CERRAR
        </v-btn>
      </v-card-actions>
    </v-card>
    <channel-numbers-history-modal
      ref="channel_numbers_history_modal"
    />
  </v-dialog>

</template>

  <script>
  import ChannelNumbersHistoryModal from './ChannelNumbersHistoryModal.vue';
  import {
  mdiClipboardClockOutline
} from '@mdi/js'

  export default {
    data: () => {
      return {
        showing_modal: false,
        channel: {},
        tab: null,
        panelGeneral: [1],
        panelUltra: [1],
        panelRespond: [1]
      }
    },
    mounted() {
    },
    components: {
      ChannelNumbersHistoryModal
    },
    props: {

    },
    methods: {
      parseValue(key, value) {
        if (key == 'status') return value? 'Activo' : 'No activo'
        if (key == 'enabled') return value? 'Funciona' : 'No funciona'
        if (value === false) return 'No'
        if (value === true) return 'Si'

        return value
      },
      async open(item) {
        console.log(this.getAllCompaniesList)
        // console.log(item)
        try {
          const response = await this.$api.getChannel(item.id)
          console.log(response)
          this.channel = response
          this.showing_modal = true
        } catch(e) {
          console.log(e)
        }
      },

      close() {
        this.showing_modal = false
      },

      getCompanyName(id) {
        const founded = this.getAllCompaniesList.find(el => el.id == id)
        return founded.name
      },

    },
    computed: {
      parserGeneral() {
        const parser = {
          id: 'ID',
          name: 'Nombre',
          description: 'Descripción',
          status: 'Activo',
          enabled: 'Estado',
          "max message daily": 'Cantidad máxima de mensajes diarios',
          companies: 'Empresas asociadas',
          campaign_message: 'Envío de mensaje de campaña',
          manual_message: 'Envío de mensaje manual',
          "pending messages": 'Mensajes pendientes',
          campaign_block: 'Bloque de campaña',
          limit_filter_day: 'Contactos a filtrar por día'
        }
        return parser
      },
      apiTitle() {
        const titles = {
          "0": "UltraMSG",
          "1": "EvolutionAPI - Whatscupon",
          "2": "EvolutionAPI Cloud"
        };
        return titles[this.channel.integration_api] || "API Desconocida";
      },
      campaignBlockParser() {
        const parser = {
          status: 'Estado: ',
          sent_messages: 'Mensajes enviados: ',
          start_break_date: 'Fecha de inicio del descanso: ',
          end_break_date: 'Fecha final del descanso: ',
        }
        return parser
      },
      parserUltra() {
        const parser = {
          api_url: 'API Url',
          token: 'Token',
          number: 'Teléfono asociado',
          instance_id: 'Instancia',
          instance_status: 'Estado de instancia',
          settings: 'Configuracion Ultramsg',
        }
        return parser
      },
      parserRespond() {
        const parser = {
          respond_sync: 'Sincronizado con Respond.io',
          respond_channel: 'Respond.io Channel',
          respond_token: 'Respond.io APIToken',
          respond_last_sinc: 'Ultima sincronizacion con Respond',
          access_token: 'Integration Access Token',
          create_contact_after_resp: 'Crear contacto en Respond sólo si responde el mensaje',
        }
        return parser
      },
      settingsParser() {
        const settingsParser = {
          send_delay: 'Delay: ',
          webhook_message_ack: 'Webhook ACK: ',
          webhook_message_create: 'Crear mensaje Webhook: ',
          webhook_message_download_media: 'Media Webhook: ',
          webhook_message_reaction: 'Reaction Webhook: ',
          webhook_message_received: 'Recibir mensajes de Webhook: ',
          webhook_url: 'Webhook URL: '
        }
        return settingsParser
      },
      getAllCompaniesList() { return this.$store.getters['session/getAllCompaniesList']() },
    },
    setup() {
      return {
        icons: {
          mdiClipboardClockOutline
        }
      }
    }
  }
  </script>
